/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import CategoricalScheme from '../../CategoricalScheme';

const schemes = [
  {
    id: 'palette1',
    label: 'Palette #1',
    colors: [
      '#4DC081',
      '#FFD43F',
      '#009EF7',
      '#F35D81',
      '#6771DC',
      '#5AA6FF',
      '#E8B300',
      '#A367DC',
      '#7AE9AC',
      '#C087CC',
      '#43CED7',
    ],
  },
  {
    id: 'palette2',
    label: 'Palette #2',
    colors: [
      '#16C404',
      '#EFAE1A',
      '#15C092',
      '#AFECEE',
      '#115DFC',
      '#0AA541',
      '#14C9F0',
      '#4F40Fe',
      '#ED556B',
      '#E54846',
      '#F5379E',
      '#154FC0',
    ],
  },
  {
    id: 'palette3',
    label: 'Palette #3',
    colors: [
      '#5882C2',
      '#FFBE49',
      '#70BBB7',
      '#F99ACA',
      '#4EC6A7',
      '#6BC142',
      '#E95F7F',
      '#ED5845',
      '#7CE8DF',
      '#4247C1',
      '#BE42C1',
      '#F9A23C',
    ],
  },
  {
    id: 'palette4',
    label: 'Palette #4',
    colors: [
      '#74E0B0',
      '#60A398',
      '#FFC301',
      '#F3EC44',
      '#3AC0EB',
      '#7326D4',
      '#FF7919',
      '#77D77B',
      '#8477D7',
      '#F13838',
      '#BDFF71',
      '#F87AEB',
    ],
  },
  {
    id: 'quotronColors',
    label: 'Quotron Colors',
    colors: [
      // Full color
      '#6BD3B3',
      '#FCC550',
      '#408184',
      '#66CBE2',
      '#EE5960',
      '#484E5A',
      '#FF874E',
      '#03748E',
      '#C9BBAB',
      '#B17BAA',
      // Pastels
      '#B5E9D9',
      '#FDE2A7',
      '#9FC0C1',
      '#B2E5F0',
      '#F6ACAF',
      '#A4A6AC',
      '#FFC3A6',
      '#81B9C6',
      '#E4DDD5',
      '#D9BDD5',
    ],
  },
].map(s => new CategoricalScheme(s));

export default schemes;
